import LineAnimation from 'components/line-animation/line-animation';
import { useEffect, useState } from 'react';
import Bio from 'sections/Bio/Bio';
import PersonalDetails from 'sections/PersonalDetails/PersonalDetails';
import ProjectsLogin from 'sections/ProjectsLogin/ProjectsLogin';
import ProjectsSection from 'sections/ProjectsSection/ProjectsSection';
import client from 'utils/contentfulClient';

const Home = () => {
  const [loggedIn, setLogin] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  // Check for logged-in state on component mount
  useEffect(() => {
    const loggedIn = localStorage.getItem('loggedIn') === 'true';
    setLogin(loggedIn);
  }, []);

  const fetchApi = async (password: string) => {
    setLoading(true);
    setError(false);
    try {
      const result = await client.getEntries({
        content_type: 'password',
        'fields.password': password,
      });
      if (result.total) {
        localStorage.setItem('loggedIn', 'true'); // Store logged-in state in localStorage
        setLogin(true);
      } else {
        setError(true);
      }
      setLoading(false);
    } catch (error) {
      setError(true);
      console.log(error);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('loggedIn'); // Clear logged-in state from localStorage
    setLogin(false);
  };

  return (
    <div className="grid gap-y-20 md:gap-60">
      <section className="overflow-x-hidden ">
        <div className="flex flex-col gap-8 ">
          <h1 className="mt-0 lg:mt-[7rem] mb-5 lg:mb-0 ">
            Hi, I’m Vishnu. <br />
            <span className="text-primary">
              Senior Product Designer & Researcher
            </span>
          </h1>
          {/* <YouCursor /> */}
          <LineAnimation />
        </div>
        {/* <Introduction /> */}
        <PersonalDetails />
      </section>
      {loggedIn ? (
        <ProjectsSection onLogin={handleLogout} />
      ) : (
        <ProjectsLogin
          loading={loading}
          error={error}
          onLogin={fetchApi}
          onError={() => setError(false)}
        />
      )}
      <Bio />
    </div>
  );
};

export default Home;
