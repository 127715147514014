import ProjectsLoginCoverImage from 'assets/project-cover.jpg';
import classNames from 'classnames';
import { ArrowRight } from 'lucide-react';
import { useState } from 'react';
import Styles from './ProjectsLogin.module.scss';

interface ProjectsLoginProps {
  loading: boolean;
  error: boolean;
  onLogin: (password: string) => void;
  onError: () => void;
}

const ProjectsLogin = ({
  loading,
  error,
  onLogin,
  onError,
}: ProjectsLoginProps) => {
  const [focus, setFocus] = useState(false);
  const [hasValue, setHasValue] = useState(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    console.log('submit', e);
    e.preventDefault();
    onLogin((e.currentTarget.elements[0] as HTMLInputElement).value);
  };

  return (
    <div id="projects" className="d-flex flex-column pb-7">
      <h2 className="pt-6 pb-6">Projects</h2>
      <div className={Styles['login']}>
        <img
          src={ProjectsLoginCoverImage}
          alt="Log in to see the projects"
          className="rounded"
        />
        <div>
          <div className="gap-5 d-flex flex-column">
            <h3 className="color-gradient-radial">Guest Login</h3>
            <p className="m-0">
              Please type the login password to view
              <br /> selected projects.
            </p>
            <form onSubmit={handleSubmit}>
              <label
                htmlFor="password"
                className={classNames(
                  { [Styles['focus']]: focus || hasValue },
                  { [Styles['loading']]: loading },
                  { [Styles['error']]: error && !loading },
                )}
              >
                <span className="text-white">Password*</span>
              </label>
              <input
                type="password"
                name="password"
                id="password"
                onClick={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                className={classNames(
                  { [Styles['focus']]: focus },
                  { [Styles['loading']]: loading },
                  { [Styles['error']]: error && !loading },
                )}
                onChange={(e) => {
                  if (error && e.currentTarget.value.length < 1) {
                    onError();
                  }
                  setHasValue(e.currentTarget.value.length > 0);
                }}
              />
              <button
                type="submit"
                style={{
                  width: 'max-content',
                  border: 'none',
                  appearance: 'none',
                  padding: 10,
                }}
                className="gap-2 py-3 d-flex align-items-center justify-content-between bg-gradient-radial rounded-xl"
              >
                <p
                  style={{
                    fontWeight: 600,
                    fontSize: '0.875rem',
                    padding: '5px 3.5rem',
                    margin: 0,
                  }}
                >
                  View Projects
                </p>
                <ArrowRight size={24} />
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectsLogin;
