import ArrowRight from 'assets/arrow-right.svg';
import { Clock } from 'lucide-react';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Styles from './ProjectBlock.module.scss';

interface ProjectProps {
  project: any;
}

const ProjectBlock = ({ project }: ProjectProps) => {
  const { slug, previewTitle, tags } = project.fields;
  return (
    <Link to={`projects/${slug}`} className={Styles['project']} id={slug}>
      <span className={Styles['project']}>
        <div className="box d-grid gap-8 pb-5">
          <h3>{previewTitle}</h3>
          <div className="d-flex flex-column gap-8">
            <div className="d-flex justify-content-between align-items-center gap-4">
              {tags && (
                <div className={Styles['tags']}>
                  {tags.map((tag: string, index: number) => {
                    return (
                      <Fragment key={index}>
                        <span>{tag}</span>
                        {index < tags.length - 1 && <span>|</span>}
                      </Fragment>
                    );
                  })}
                </div>
              )}
              <div className="d-flex alin-items-center justify-content-between gap:4 md:gap-8">
                <div className="d-flex align-items-center gap-2">
                  <Clock />
                  <span className={Styles['read']}>5 min read</span>
                </div>
                <img
                  src={ArrowRight}
                  alt="Open"
                  style={{ width: '2.625rem' }}
                  className={Styles['arrow']}
                />
              </div>
            </div>
            {project.fields.featuredImage && (
              <img
                src={project.fields.featuredImage.fields.file.url}
                alt={project.title}
                className="md:max-h-[445px] object-cover rounded"
              />
            )}
          </div>
        </div>
      </span>
    </Link>
  );
};

export default ProjectBlock;
