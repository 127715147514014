import { AppContext } from 'context/app.context';
import { ArrowRight } from 'lucide-react';
import { Fragment, useContext } from 'react';
import ProjectBlock from 'sections/ProjectsSection/ProjectBlock/ProjectBlock';

interface ProjectsSectionProps {
  onLogin: () => void;
}

const ProjectsSection = ({ onLogin }: ProjectsSectionProps) => {
  const app = useContext(AppContext);

  return (
    <div id="projects" className="grid gap-8">
      <div className="pt-6 d-flex justify-content-between">
        <h2 className="">Projects</h2>
        <div className="justify-center gap-3 d-flex flex-column align-items-end">
          <button onClick={onLogin} className="link text-[1.25rem]">
            Hide Projects
            <ArrowRight size={20} />
          </button>
        </div>
      </div>
      <div className="gap-4 d-flex flex-column pb-7">
        {app.projects &&
          app.projects
            .sort((a: any, b: any) => {
              return a.fields.position - b.fields.position;
            })
            .map((project: any, index: number) => (
              <Fragment key={index}>
                <ProjectBlock project={project} />
              </Fragment>
            ))}
      </div>
    </div>
  );
};

export default ProjectsSection;
