import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import classNames from 'classnames';
import { Fragment } from 'react';

interface ProjectTemplateProps {
  project: any;
}

const ContentBlock = ({ section }: any) => {
  const { title, contentText, label } = section.fields;
  return (
    <div>
      {label && (
        <h3 className="font-['Open_Sans'] font-normal !text-[20px] uppercase">
          {label}
        </h3>
      )}
      {title && (
        <h2 className="font-['Open_Sans'] font-normal !text-[34px] pb-6">
          {title}
        </h2>
      )}
      {contentText && (
        <div
          className={classNames(
            '[&>*]:text-lg [&_h1]:!text-[40px] [&_h1]:!leading-[48px] [&_h1]:!font-normal h-full',
            {
              'flex justify-center items-center': !label && !title,
            },
          )}
        >
          {documentToReactComponents(contentText)}
        </div>
      )}
      {section.fields?.contentMedia && (
        <>
          {section.fields?.contentMedia?.fields?.file?.contentType.includes(
            'image',
          ) && (
            <img
              src={section.fields?.contentMedia?.fields?.file?.url}
              alt={section.fields?.contentMedia?.fields?.file?.title}
              className="object-contain w-auto h-full my-auto"
            />
          )}
        </>
      )}
    </div>
  );
};

const ProjectTemplate = ({ project }: ProjectTemplateProps) => {
  const { title, content, fullWidthImages, gallery, contentBottom } =
    project.fields;
  return (
    <div className="grid gap-20">
      <h1>{title}</h1>

      <div className="grid md:grid-cols-2 gap-x-12 gap-y-14">
        {content?.length &&
          content?.map((section: any, index: number) => (
            <Fragment key={index}>
              <ContentBlock section={section} key={index} />
            </Fragment>
          ))}
      </div>
      {fullWidthImages?.length && (
        <div className="grid">
          {fullWidthImages.length &&
            fullWidthImages?.map((image: any, index: number) => {
              return (
                <img
                  src={image.fields?.file?.url}
                  alt={image?.field?.files?.alt}
                  className={classNames(
                    'object-covermy-auto h-full w-auto mx-auto',
                  )}
                  key={index}
                />
              );
            })}
        </div>
      )}
      {gallery?.length && (
        <div className="grid md:grid-cols-2 gap-x-12 gap-y-14">
          {gallery.length &&
            gallery?.map((image: any, index: number) => {
              const rounded =
                image?.metadata?.tags.find(
                  (tag: any) => tag.sys?.id === 'rounded',
                )?.sys?.id === 'rounded';
              return (
                <img
                  src={image.fields?.file?.url}
                  alt={image?.field?.files?.alt}
                  className={classNames(
                    'object-covermy-auto h-full w-auto mx-auto',
                    {
                      rounded: rounded,
                    },
                  )}
                  key={index}
                />
              );
            })}
        </div>
      )}

      <div className="grid md:grid-cols-2 gap-x-12 gap-y-14">
        {contentBottom?.length &&
          contentBottom?.map((section: any, index: number) => (
            <Fragment key={index}>
              <ContentBlock section={section} key={index} />
            </Fragment>
          ))}
      </div>
    </div>
  );
};

export default ProjectTemplate;
