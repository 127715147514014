import Styles from './Footer.module.scss';
import { ArrowRight } from 'lucide-react';

const Footer = () => {
  // Get the current date
  const currentDate = new Date();

  // Format the date as "Last updated: Month Year"
  const lastUpdatedDate = currentDate.toLocaleDateString(undefined, {
    year: 'numeric',
    month: 'long',
  });

  return (
    <footer>
      <div className={Styles['footer']}>
        <div className={Styles['left']}>
          <div className={Styles['heading-wrapper']}>
            <h4>Say hi</h4>
            <div className={Styles['emoji']}>👋</div>
          </div>
          <span>
            <a href="mailto:getvraj@gmail.com">getvraj@gmail.com</a>
          </span>
        </div>
        <div className={Styles['center']}>
          <a
            href="https://www.linkedin.com/in/designervishnuraj/"
            target="_blank"
            rel="noreferrer"
          >
            LinkedIn
            <ArrowRight />
          </a>
          <a
            href="cv_senior_product-designer-vishnu.pdf"
            target="_blank"
            rel="noreferrer"
            download
          >
            Resume
            <ArrowRight />
          </a>
        </div>
        <div className={Styles['right']}>
          <p>
            Designed by{' '}
            <a
              href="/cv_senior_product-designer-vishnu.pdf"
              target="_blank"
              rel="noreferrer"
              download
            >
              Vishnu,{' '}
            </a>
            Built by{' '}
            <a
              href="https://www.linkedin.com/in/szilas-miklos/"
              target="_blank"
              rel="noreferrer"
            >
              Miklós Szilas
            </a>
            <br />
            <>Last updated: {lastUpdatedDate}</>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
