import { FC, ReactNode, SetStateAction, createContext, useState } from 'react';

const DEFAULT_APP_CONTEXT = {};

export const AppContext = createContext<any>({
  ...DEFAULT_APP_CONTEXT,
  setApp: () => {},
});

export const AppProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const setApp = (appState: SetStateAction<any>) => {
    setState((prevState: any) => ({
      ...prevState,
      ...(typeof appState === 'function' ? appState(prevState) : appState),
    }));
  };

  const initialState: any = {
    ...DEFAULT_APP_CONTEXT,
    setApp,
  };

  const [state, setState] = useState<any>(initialState);

  return <AppContext.Provider value={state}>{children}</AppContext.Provider>;
};
