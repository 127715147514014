/* eslint-disable react-hooks/exhaustive-deps */
import { Position } from 'enums';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useScrollToPosition = (position: Position | string) => {
  const location = useLocation();

  useEffect(() => {
    if (position.startsWith('#')) {
      console.log('useScrollToPosition', position);
      const element = document.querySelector(position);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      switch (position) {
        case Position.TOP:
          window.scrollTo(0, 0);
          break;
        case Position.BOTTOM:
          window.scrollTo(0, document.body.scrollHeight);
          break;
        default:
          window.scrollTo(0, 0);
          break;
      }
    }
  }, [location.pathname, position]);
};

export default useScrollToPosition;
