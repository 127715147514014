import { ReactNode } from 'react';
import Styles from './PersonalDetails.module.scss';
import classNames from 'classnames';
import { Mouse } from 'lucide-react';

type PersonalDetail = {
  title: string;
  value: ReactNode;
};

const PersonalDetails = () => {
  const personalDetails: PersonalDetail[] = [
    { title: 'Ethnicity', value: <p>Indian</p> },
    { title: 'Current Location', value: <p>Hamburg, Germany</p> },
    {
      title: 'Education',
      value: (
        <p>
          MSc Human Computer Interaction,
          <br /> Bachelor’s degree in Engineering
        </p>
      ),
    },
    {
      title: 'Skills',
      value: (
        <p>
          Research, UX, Interaction Design,
          <br />
          Product Design & Strategy
        </p>
      ),
    },
  ];
  return (
    <>
      <div
        className={classNames(
          Styles['personal-details'],
          'border-[2px] border-[#D587FB] p-4 rounded-xl',
          'flex flex-col md:flex-row md:justify-between w-fit-content mx-auto',
        )}
      >
        {personalDetails.map((item, index) => {
          const { title, value } = item;

          return (
            <div key={index} className="p-4 d-flex flex-column">
              <h4 className="font-['Open_Sans']">{title}</h4>
              <>{value}</>
            </div>
          );
        })}
      </div>

      <Mouse
        className="mx-auto w-[31px] h-[50px] hover:cursor-pointer"
        onClick={() => {
          document
            .getElementById('projects')
            ?.scrollIntoView({ behavior: 'smooth' });
        }}
      />
    </>
  );
};

export default PersonalDetails;
