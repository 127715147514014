import Project from './components/layouts/Project/Project';
import { AppProvider } from 'context/app.context';
import { createRoot } from 'react-dom/client';
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import './styles/index.scss';

const routes = [
  {
    path: '/',
    element: <App />,
  },
  {
    path: 'projects/:projectName',
    element: <Project />,
  },
  {
    path: '*',
    element: <Navigate to={'/'} />,
  },
];

const router = createBrowserRouter(routes);

createRoot(document.getElementById('root') as HTMLElement).render(
  <AppProvider>
    <RouterProvider router={router} />
  </AppProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
