import Layout from 'components/layouts/AppLayout/AppLayout';
import { AppContext } from 'context/app.context';
import Home from 'pages/Home/Home';
import { useContext, useEffect, useState } from 'react';
import client from 'utils/contentfulClient';

const App = () => {
  const app = useContext(AppContext);
  const [, setError] = useState(false);
  const [, setLoading] = useState(false);

  const fetchApi = async () => {
    setLoading(true);
    setError(false);
    try {
      const result = await client.getEntries({
        content_type: 'portfolioProject',
      });
      app.setApp((prevState: any) => ({
        ...prevState,
        projects: result.items,
      }));
      setLoading(false);
    } catch (error) {
      setError(true);
      console.log(error);
    }
  };

  useEffect(() => {
    if (!app.projects) fetchApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <Home />
    </Layout>
  );
};

export default App;
