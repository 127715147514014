import VishnuImage from 'assets/vishnuraj.webp';
import Styles from './Bio.module.scss';
import CurlyLines from 'assets/curly-lines.svg';

const Bio = () => {
  return (
    <section>
      <img
        src={CurlyLines}
        alt="Curly Lines"
        className="block w-[170px] ml-auto pb-8 md:pb-0"
      />
      <h2 className="pt-4 pb-8">Oh yes, I will keep it simple.</h2>
      <div className={Styles['bio']}>
        <div className={Styles['featured-image']}>
          <img src={VishnuImage} alt="Vishnu Raj" className="rounded" />
        </div>
        <div className="[&>p]:text-[20px]">
          <p>
            Ever since I can remember, I've been that person who can't help but
            notice the little things – the quirky details, the hidden gems. It's
            just in my nature.
          </p>
          <p>
            Having hands on experience in the industry and the academic
            background in interaction design, honed my deep understanding of the
            design process, principles and multifaceted skills. During this
            tenure, I also conducted research on topics such as design thinking,
            stakeholder management, and how can design be carried out in
            extremely constrained environments.
          </p>
          <p>
            I am inspired by Leonardo Da Vinci, Donald A Schon & Don Norman.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Bio;
