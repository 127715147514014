import { AppContext } from 'context/app.context';
import { Position } from 'enums';
import useScrollToPosition from 'hooks/useScrollToPosition';
import { ArrowUp } from 'lucide-react';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import client from 'utils/contentfulClient';
import AppLayout from '../AppLayout/AppLayout';
import ProjectHeader from './ProjectHeader';
import ProjectTemplate from './templates/project.template';

const Project = () => {
  const app = useContext(AppContext);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchApi = async () => {
    setLoading(true);
    setError(false);
    try {
      const result = await client.getEntries({
        content_type: 'portfolioProject',
        include: 4,
      });
      app.setApp((prevState: any) => ({
        ...prevState,
        projects: result.items,
      }));
      setLoading(false);
    } catch (error) {
      setError(true);
    }
  };

  useEffect(() => {
    if (!app.projects) fetchApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app.projects]);

  const { projectName } = useParams<{ projectName: string }>();

  useScrollToPosition(Position.TOP);
  if (!app.projects) return null;

  const project = app.projects.find(
    (project: any) => project.fields.slug === projectName,
  );

  return (
    <AppLayout>
      {loading && <div>Loading...</div>}
      {error && <div>Error...</div>}
      <ProjectHeader title={project.title} />
      <ProjectTemplate project={project} />

      <div className="flex flex-column justify-center align-items-end gap-3 pt-[5rem] lg:pb-[5rem]">
        <button
          onClick={() => {
            // scroll to top, smooth
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
          }}
          className="link text-[1.25rem] !gap-8"
        >
          Back to top
          <ArrowUp size={24} className="!transform-none" />
        </button>
      </div>
    </AppLayout>
  );
};

export default Project;
