import classNames from 'classnames';
import { MoveLeft } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import Styles from './Project.module.scss';

interface ProjectHeaderProps {
  title: string;
}

const ProjectHeader = ({ title }: ProjectHeaderProps) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const [readProgress, setReadProgress] = useState(0);
  const calculateReadProgress = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;
    const windowHeight = scrollHeight - clientHeight;
    const scrollPercentage = (scrollTop / windowHeight) * 100;

    setReadProgress(scrollPercentage);
  };

  useEffect(() => {
    window.addEventListener('scroll', calculateReadProgress);

    return () => {
      window.removeEventListener('scroll', calculateReadProgress);
    };
  }, []);

  return (
    <div
      ref={headerRef}
      className={classNames(
        Styles['header'],
        'sticky top-0 flex flex-col items-center justify-between gap-4 py-5 z-[1]',
      )}
      id="project-header"
    >
      <div className="flex items-center justify-between gap-4 w-100">
        <a
          href={`/`}
          className={classNames(
            Styles['nav-back'],
            'flex gap-2 items-center justify-center text-primary',
          )}
        >
          <MoveLeft
            style={{ width: '2.625rem' }}
            className={Styles['nav-back']}
            size={32}
          />
          <span>Back to home</span>
        </a>
        {readProgress > 0 && <h2>{title}</h2>}
      </div>
      {readProgress > 0 && (
        <div className={Styles['read-progress']}>
          <div
            id="readprogress"
            className={Styles['read-progress-bar']}
            style={{
              width: `${readProgress}%`,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ProjectHeader;
