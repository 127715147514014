import { ReactNode } from 'react';
import Footer from 'sections/Footer/Footer';
import Styles from './AppLayout.module.scss';

interface LayoutProps {
  children: ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  return (
    <div className={Styles['app-layout']}>
      <div className={Styles['container']}>
        {children}
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
